import { alpha, withStyles } from '@material-ui/core/styles';
// ----------------------------------------------------------------------

const GlobalStyles = withStyles(theme => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%',
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        width: 8,
        height: 6,
        backgroundColor: theme.palette.divider
      },
      '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
        border: 'none',
        borderRadius: 8,
        backgroundColor: alpha(theme.palette.grey[600], 0.48)
      }
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: {
      color: theme.palette.primary.main
    },
    img: {
      display: 'block',
      maxWidth: '100%'
    },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    },
    
    // '.ant-upload': {
    //   width: "200px",
    //   height: "200px",
    // },
    
    '.ant-upload-list-picture-card-container': {
      width: "250px",
      height: "250px",
      [theme.breakpoints.down('lg')]: {
        maxWidth: "220px",
        height: "220px",
      },
    },
    '.ant-upload.ant-upload-select-picture-card': {
      width: "250px",
      height: "250px",
      backgroundColor: "#00000000",
      [theme.breakpoints.down('lg')]: {
        maxWidth: "220px",
        height: "220px",
      },
    },


    '.react-grid-item.resizing': {
      opacity: 0.9,
    },
    // '.react-grid-item.static': {
    //   background: '#cce',
    // },
    // '.react-grid-item .text': {
    //   fontSize: '24px',
    //   textAlign: 'center',
    //   position: 'absolute',
    //   top: 0,
    //   bottom: 0,
    //   left: 0,
    //   right: 0,
    //   margin: 'auto',
    //   height: '24px',
    // },
    '.react-grid-item .minMax': {
      fontSize: '12px',
    },
    '.react-grid-item .add':{
      cursor: 'pointer',
    },
   ' .react-grid-dragHandleExample': {
      cursor: 'move',
      cursor: 'grab',
      cursor: '-moz-grab',
      cursor: '-webkit-grab',
    },
    'li b': {
      fontSize: '19px',
      lineHeight: '14px',
    },


    '.react-grid-layout': {
      position: 'relative',
      transition: 'height 200ms ease',
    },
    '.react-grid-item':{
      transition: 'all 200ms ease',
      'transition-property': 'left, top',
    },
    '.react-grid-item.cssTransforms': {
      'transition-property': 'transform',
    },
   '.react-grid-item.resizing': {
      'z-index': 1,
      'will-change': 'width, height',
    },
    
    '.react-grid-item.react-draggable-dragging': {
      transition: 'none',
      'z-index': 3,
      'will-change': 'transform',
    },
    
    '.react-grid-item.react-grid-placeholder': {
      background: 'red',
      opacity: 0.2,
      'transition-duration': '100ms',
      'z-index': 2,
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      '-o-user-select': 'none',
      'user-select': 'none',
    },
    
    '.react-grid-item > .react-resizable-handle-se' : {
      position: 'absolute',
      width: '20px',
      height: '20px',
      bottom: 0,
      right: 0,
      //background: 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=")',
      'background-position': 'bottom right',
      padding: '0 3px 3px 0',
      'background-repeat': 'no-repeat',
      'background-origin': 'content-box',
      boxSizing: 'border-box',
      cursor: 'se-resize',
    },
    
  }
}))(() => null);

export default GlobalStyles;
