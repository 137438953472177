import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

LogoName.propTypes = {
  className: PropTypes.string
};

function LogoName({ className, showDark, ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src= {!showDark? "/static/brand/light_logo_name.png": "/static/brand/dark_logo_name.png"}
      height={45}
      className={className}
      {...other}
    />
  );
}

export default LogoName;