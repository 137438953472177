// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/admin',
  app: '/app',
  docs: '/docs',
  setup: '/setup'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
  },
  contactUs: '/contact-us',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
};

export const PATH_HOME = {
  components: '/components',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  main: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
    ecommerce: path(ROOTS.app, '/dashboard/ecommerce'),
    analytics: path(ROOTS.app, '/dashboard/analytics')
  },

};
